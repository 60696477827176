<template>
    <v-container fluid>
        
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
<v-row>
    <v-col>
 <v-form
    ref="orgForm"
    v-model="valid"
    
  >
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title  class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
            <v-text-field 
           :dark="$store.getters.getColorPalette().isDark" 
            :loading="loading"
      v-model="form.name"
      :counter="25"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
    <v-col v-if="$store.state.user && $store.getters.getAccessByLevels(['alphaaccess']) && !(this.notificationGroup && this.notificationGroup.group_id)">
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-select
            v-if="!loading"
            v-model="form.notification_type"
            :items="notificationTypeOptions"
            :label="$store.getters.getTextMap().notification_type"
            dense
            filled
            item-text="label"
            item-value="value"
            required
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
    <v-text-field
    :dark="$store.getters.getColorPalette().isDark" 
      v-model="form.description"
      :counter="35"
      :rules="msgRules"
      :label="$store.getters.getTextMap().description"
      required
    ></v-text-field>
        </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
        
            @click="onSubmit"
          >
         {{ $store.getters.getTextMap().submit }}
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().cancelbtnColor"
          :dark="$store.getters.getColorPalette().isDark" 
            @click="onReset"
          >
          {{ $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
 </v-form>
    </v-col>
</v-row>
    </v-container>
                
</template>
<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'CreateEmailGroup',
    props:['notificationGroup'],
    components:{
        InfoAlert
    },
    data(){
        return {
            form:{
                name:null,
                notification_type:'email'
            },
            notificationTypeOptions:[
              {label:this.$store.getters.getTextMap().email,value:'email'},
              {label:this.$store.getters.getTextMap().sms,value:'sms'}
            ],
            api:'createEmailGroup',
            title:'Create Email Group',
                loading:false,
                info:null,
                infoMessage:"Group Created",
                showDismissibleAlert:false,
                valid:false,
        nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 11 characters',
      ],
      msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
        }
    },
    mounted(){
      if(this.notificationGroup && this.notificationGroup.group_id){
        this.api='updateEmailGroup'
        this.title='Update Email Group'
        this.infoMessage="Group Updated"
        this.form = Object.assign({},this.notificationGroup)
      }
    },
    methods:{
        onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + this.api, this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success" && response.data.group) {
            
            
            //console.log(response.data.jwt);
            this.$store.commit("addNotificationGroup",response.data.group);
            this.$store.dispatch("refreshNotificationGroups")
            this.info = this.infoMessage;
          this.showDismissibleAlert = true;
          this.loading = false;
            this.onReset()
            this.$emit('close')
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.onReset()
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        onReset(){
            this.$refs.orgForm.reset()
            this.$emit('close')
            
            
        }
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:#fff
}
.v-sheet.v-card{
  background-color: #263238
} */
</style>