<template>
    <v-container fluid>
        <v-expansion-panels
      multiple
      hover
      tile
     :dark="$store.getters.getColorPalette().isDark"
    >
      <v-expansion-panel 
      
      :dark="$store.getters.getColorPalette().isDark"
      expand
        v-for="param in params"
        :key="param.id"
       
      >
        <v-expansion-panel-header :color="$store.getters.getColorPalette().expansionPanelHeaderColorName" ><h3 class="">{{param.name}}</h3> </v-expansion-panel-header>
        <v-expansion-panel-content :color="$store.getters.getColorPalette().background2ColorCode">
            <v-col align="right">
                <span><v-btn small color="grey" :dark="$store.getters.getColorPalette().isDark" @click="switchOffWhole(param.id)">Off</v-btn><v-btn :dark="$store.getters.getColorPalette().isDark" small color="green" @click="switchOnWhole(param.id)">On</v-btn></span>
            </v-col>
            <v-row style="margin:10px;">
                <v-col v-for="device in param.devices" :key="device.param_id">

                    <v-card dense :color="colorMap[device.state]">
                        <v-card-title>{{device.name}}</v-card-title>
                    <v-card-text>
                    <v-row >
                        <v-col v-if="device.state==1">
                            {{$store.getters.getTextMap().device_switched_on}}
                        </v-col>
                        <v-col v-else>
                            {{$store.getters.getTextMap().device_switched_off}}
                        </v-col>
                        <v-col align="right">
                                <span><v-btn v-if="device.state==1" small color="grey" :dark="$store.getters.getColorPalette().isDark" @click="switchOff(device.param_id,param.id)">{{$store.getters.getTextMap().off}}</v-btn><v-btn v-if="device.state==0" :dark="$store.getters.getColorPalette().isDark" small color="green" @click="switchOn(device.param_id,param.id)">{{$store.getters.getTextMap().on}}</v-btn></span>
                            </v-col>
                    </v-row>
                    </v-card-text>
    
                    </v-card>
                </v-col>
            </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-container>
</template>

<script>
// import Workspace from '@/components/items/Workspace'
export default {
    name:'ParamDummyList',
    data(){
        return {
            color:'blue',
      params:[
            {id:'df',
            name:'1st Floor lighting',
            devices:[
            {param_id:'x', name:'Admin office Light', state:0},
            {param_id:'y', name:'Common Area Lights', state:1},
            {param_id:'z', name:'Break out Area lighting', state:0},
            ]
            },
            {id:'df2',
            name:'2nd Floor Lighting',
            devices:[
            {param_id:'a', name:'Conference Room Lights', state:0},
            {param_id:'b', name:'Break out area lights', state:1},
            {param_id:'c', name:'Common area Lights', state:1},
            {param_id:'d', name:'Main Bay Lights', state:1},
            ]
            },
        ],
        colorMap:['grey','green']
    }
    },
    components:{
        // Workspace
    },
    methods:{
        switchOn(device_id,group_id){
            for(let i of this.params){
                if(i['id']===group_id){
                    for(let j of i['devices']){
                        if(j['param_id']===device_id){
                            j['state']=1
                        }
                    }
                }
            }
        },
        switchOff(device_id,group_id){
            for(let i of this.params){
                if(i['id']===group_id){
                    for(let j of i['devices']){
                        if(j['param_id']===device_id){
                            j['state']=0
                        }
                    }
                }
            }
        },
        switchOnWhole(group_id){
            for(let i of this.params){
                if(i['id']===group_id){
                    for(let j of i['devices']){
                            j['state']=1
                    }
                }
            }
        },
        switchOffWhole(group_id){
            for(let i of this.params){
                if(i['id']===group_id){
                    for(let j of i['devices']){
                            j['state']=0
                    }
                }
            }
        },
    },
    computed:{
        // workspaces(){
        //     return this.$store.state.workspaces;
        // }
    }
}
</script>
<style scoped>
.v-expansion-panels{
    margin-top: 3%;
    margin-left: 1%;
   
}
</style>